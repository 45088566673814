

import Logo from "../../assets/images/Logo.png";

import { Link } from "react-scroll";

function Tobbar(params) {
    return (

        <nav className="bg-white border-gray-200 mt-3 px-2 sm:px-4 py-2.5 fit rounded dark:bg-gray-800">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                  <a href="/home" className="flex  items-center">
                  <img src={Logo} className="mr-3 h-6 sm:h-10" alt="Flowbo"/>
              </a>

                <div className="flex md:order-2">
                    <Link type="button" smooth={true}
                          activeClass="active"
                          to="contactus"
                          spy={true}
                          className="text-white   xl:w-40 xl:text-lg    bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >Contact Us
                    </Link>
                    <button data-collapse-toggle="mobile-menu-4" type="button"
                            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="mobile-menu-4" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                  clip-rule="evenodd"></path>
                        </svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div className="hidden md:mr-[80px] lg:mr-[280px] xl:mr-[520px] 2xl:mr-[700px] justify-between items-center w-full md:flex md:w-auto md:order-1"
                     id="mobile-menu-4">
                    <ul className="flex  flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <li>
                            <a href="/home"
                               className="block py-2 text-2xl text-[#7B7B82] pr-4 pl-3 hover:text-red-700  rounded md:bg-transparent  md:p-0"
                               aria-current="page">Home</a>
                        </li>
                        <li>
                            <Link   smooth={true}
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    className="block py-2 text-2xl pr-4 pl-3 hover:text-red-700  text-[#7B7B82] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-600 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                About</Link>
                        </li>
                        <li>
                            <Link   smooth={true}
                                    activeClass="active"
                                    to="services"
                                    spy={true}
                                    className="block py-2  pr-4 text-2xl pl-3 hover:text-red-700  text-[#7B7B82] border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-red-600 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</Link>
                        </li>

                    </ul>
                </div>

            </div>
        </nav>

    );
}
export default Tobbar;