import why from "../../../assets/images/why.png";

function Why_we_exist() {
    return (
        <div className="flex mt-56 sm:flex-wrap  flex-wrap  md:space-x-24  justify-center pl-10 pr-10 sm:pl-0 sm:pr-0">


            <div className="flex-col justify-center " >
                <h1 className="font-bold  bg-text-[#121127] text-4xl  sm:text-5xl md:text-6xl lg:text-7xl">Why we Exist</h1>
                <h1 className=" mt-2  text-[#A3A3A8]  text-xl sm:mt-4 sm:w-[600px] sm:text-2xl">Owners of projects often do not have sufficient knowledge about the marketing process and its requirements, in addition to their ignorance of the benefit of e-marketing, which has become a very important factor in the course of work. Especially after the public turned to him during the quarantine period.</h1>

            </div>
            <img src={why} className=" w-96 m-auto mt-4 sm:mt-0 sm:w-[550px] h-auto rounded-lg"
                 alt=""/>



        </div>
    );
}
export default Why_we_exist;