import React from 'react';
import founder from "../../../assets/images/founder.png";


function Founder() {
    return (
    <div className="flex mt-56 sm:flex-wrap  flex-wrap  md:space-x-24  justify-center pl-10 pr-10 sm:pl-0 sm:pr-0">

        <div className="flex-col justify-center " >
            <h1 className="font-bold  bg-text-[#121127] text-left text-4xl sm:text-justify  sm:text-5xl md:text-6xl lg:text-6xl">Founder</h1>
            <h1 className=" w-80 text-center sm:text-left    mt-1  text-[#A3A3A8]  text-xl sm:mt-4 sm:text-2xl">Take a look at the range of marketing and advertising services .</h1>

        </div>
        <img src={founder} className="sm:w-[400px] w-96 mt-4 sm:mt-0 h-auto rounded-lg "
             alt=""/>



    </div>
    );
}
export default Founder;