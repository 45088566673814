import what from  '../../../assets/images/what.png';

function What_we_do() {
    return (

        <div id="what_we" className="flex mt-56 sm:flex-wrap  flex-wrap-reverse md:space-x-24  justify-center pl-10 pr-10 sm:pl-0 sm:pr-0">

            <img src={what} className="  w-96  mt-4 sm:mt-0 sm:w-[550px] h-auto rounded-lg"
                 alt=""/>


            <div className=" ">
                {/*//pr-9 w-[750px]*/}
                <h1 className="font-bold  bg-text-[#121127] text-4xl sm:text-5xl md:text-6xl lg:text-6xl">What We Do</h1>
                <h1 className="mt-2  text-[#A3A3A8]  text-xl sm:mt-4 sm:w-[600px] sm:text-2xl">We will save you some time, effort and relationships, in order to reach the best result that Strengthen and And expand the scope Of your business, because we have a diverse and sophisticated team, and our agency is linked to several companies and excellent sources as well as experience in the field.</h1>

            </div>

        </div>
    );
}
export default What_we_do;