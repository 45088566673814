import about from  '../../../assets/images/about.png';

function About() {
    return (

        <div className="flex mt-56 mb-40 sm:flex-wrap  flex-wrap-reverse md:space-x-24  justify-center pl-10 pr-10 sm:pl-0 sm:pr-0 justify-center">

            <img src={about} className=" w-96  mt-4 sm:mt-0 sm:w-[550px] h-auto rounded-lg"
                 alt=""/>

            <div className="flex-col justify-center " >
                <h1 className="font-bold bg-text-[#121127] text-4xl  sm:text-5xl md:text-6xl lg:text-6xl" id="about" >About</h1>
                <h1 className="mt-2 text-[#A3A3A8]  text-xl sm:mt-4 sm:w-[600px] sm:text-2xl">we are marketing agency, our job is to provide integrated marketing services, we study the market situation and determine the target market for entrepreneurs.Then we conduct a feasibility study to determine the advertising needs of the project.

                    Then we proceed to work as needed.</h1>

            </div>

        </div>
    );
}
export default About;