import Nextra_mission from "./Nextra_mission/nextra_mission";
import What_we_do from "./what_we_do/what_we_do";
import Why_we_exist from "./Why_we_exsit/why_we_exist";
import About from "./About/about";
import Services from "./Services/services";
import Founder from "./Founder/founder";
import Footer from "./Footer/footer";
import Tobbar from "../Topbar/topbar";

function Home() {
    return (
        <div>
            <Tobbar/>
            <Nextra_mission></Nextra_mission>
            <What_we_do></What_we_do>
            <Why_we_exist></Why_we_exist>
            <About></About>
            <Services></Services>
            <Founder></Founder>
            <Footer/>
        {/**/}
        </div>
    );
}
export default Home;