
import Logo from "../../assets/images/Logo.png";

import { Link } from "react-scroll";

function TobbarService(params) {
    return (

        <nav className="bg-white border-gray-200 mt-3 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <a href="/home" className="flex  items-center">
                    <img src={Logo} className="mr-3 h-6 sm:h-10" alt="Flowbo"/>
                </a>

                <div className="flex md:order-2">
                    <Link type="button" smooth={true}
                          activeClass="active"
                          to="contactus"
                          spy={true}
                          className="text-white   xl:w-40 xl:text-lg    bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    >Contact Us
                    </Link>

                </div>


            </div>
        </nav>

    );
}
export default TobbarService;