import  nextra from '../../../assets/images/nextra.png';
import { Link,Button } from "react-scroll";

function Nextra_mission() {
    return (
        <div className="flex flex-col justify-between  items-center mt-20 sm:mt-40">
            <h1 className="container  text-center font-bold  bg-text-[#121127]  text-4xl  sm:text-6xl md:text-8xl  lg:text-8xl xl:text-8xl 2xl:text-8xl ">Nextra Mission</h1>
            <h1 className="container mt-2 text-center pl-8 pr-8  sm:pr-0 sm:pl-0 text-[#A3A3A8] text-2xl  sm:text-3xl sm:w-[900px] sm:mt-4 ">nextra mission is to steer clients to success when
                they are at the crossroads of challenge and risk to make brand new standard to Mosul’s market.</h1>
            <div className="container text-center mt-6  justify-between items-center flex-row sm:mt-12">

                    <Link  smooth={true}
                             activeClass="active"
                             to="contactus"
                             spy={true}
                             className=" text-white md:mr-4  lg:text-2xl   bg-red-600  focus:ring-4 focus:ring-red-600 font-medium rounded-lg text-sm px-5  py-2.5 text-center mr-3 md:mr-0 dark:bg-red-600 dark:focus:ring-red-800"
                    >Contact Us
                    </Link>

                <Link type="button" smooth={true}
                        activeClass="active"
                        to="what_we"
                        spy={true}
                        className="text-white ml-8  bg-black   lg:text-2xl focus:ring-4 focus:ring-black font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-red-600  ">
                    Read more
                </Link>
            </div>
            <img src={nextra} className=" mt-6 w-[550px]  h-auto sm:mt-12 sm:w-full pr-4 pl-4 sm:pr-24 sm:pl-24" alt="..."/>
        </div>
    );
}

export default Nextra_mission;