import TobbarService from "../Topbar/top_service";
import {useEffect, useState} from "react";
import axios from "axios";
import EventsLoader from "../../components/loading_service";
import {useParams} from "react-router-dom";
import FooterService from "../Footer/footer_service";
function Service() {
    const [service,setService]=useState([]);
    const [loading,setLoading]=useState(true);
    const {id} =useParams()
    useEffect(()=>{
        axios.get(`https://nextra-backen.herokuapp.com/api/nextra/get/${id}`)
            .then((response) => {
                const services = response.data;
                setService(services);
                setLoading(false)}).catch((err)=>{

            setLoading(true) })
    },[]);
    return (
       <div>
           <TobbarService/>
           {
               loading?
                   <EventsLoader/>
                   :
               <div className="flex mt-20 sm:mt-36 flex-col  md:flex-row    justify-center pl-10 pr-10 sm:pl-0 sm:pr-0">



               <div>
               <img src={service.img} className=" pl-4 pr-4 ms:pl-0 ms:pr-0 w-[650px] h-full lg:h-[700px] " alt="..."/>

               </div>
               <div className="flex flex-col  pl-10 mt-10 sm:mt-30">
               <h1 className="   font-bold  bg-text-[#121127]  text-4xl  sm:text-5xl md:text-6xl lg:text-7xl ">{service.title}</h1>
               <h1 className="   mt-2   text-[#A3A3A8]  text-xl sm:mt-2 sm:w-[500px]  sm:text-2xl">{service.sub_description}</h1>
               <h1 className=" mt-2   text-[#A3A3A8] text-xl  sm:text-2xl sm:w-[500px] sm:mt-2 ">{service.description}</h1>
               </div>


               </div>
           }
           <FooterService/>
       </div>

);
}
export  default Service;