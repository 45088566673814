import {Route,Routes} from "react-router-dom";
import Home from "./pages/Body/home";
import Service from "./pages/Service/service";


function App() {
  return (
      <Routes>
          <Route path="/home" element={<Home />}/>
          <Route path="/" element={<Home />}/>
          <Route path="/service/:id" element={<Service />}/>
          {/*<Route element={ViewNotFound} />*/}
      </Routes>
  );
}

export default App;